import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout/Layout";
import GlossaryAsideMenu from "../components/GlossaryAsideMenu";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

import { getDefinedTermPopUpItem } from "../helpers";

export default ({ data, location }) => {
  const terms = data.allContentfulDefinedTerm.nodes;
  const definedTermsPopUpItems = terms.map(getDefinedTermPopUpItem);

  const glossaryItemData = data.contentfulDefinedTerm;

  const getGlossaryItemStructuredData = () => {
    return JSON.stringify({
      "@type": ["DefinedTerm", "Frends integration term"],
      "@id": `https://frends.com/glossary/${glossaryItemData.slug}`,
      "name": glossaryItemData.title,
      "termCode": glossaryItemData.title,
      "description": glossaryItemData.shortDescription.shortDescription,
      "inDefinedTermSet": "https://frends.com/glossary"
    })
  }

  return (
    <Layout
      title={glossaryItemData.title}
      pageContentId="glossary-content"
      location={location}
      structuredData={getGlossaryItemStructuredData()}
    >
      <main id="glossary-content" className="section">
        <div className="container">
          <div className="is-hidden-desktop">
            <a
              className="is-orange-border-bottom-left"
              href="/glossary"
            >
              Go back
            </a>

            <GlossaryContentSection
              title={glossaryItemData.title}
              description={glossaryItemData.description}
              popUpItems={definedTermsPopUpItems}
            />
          </div>

          <div className="is-hidden-touch columns is-multiline margin-y-40">
            <div
              className="column is-4"
              style={{
                position: 'sticky',
                top: '0',
                alignSelf: 'flex-start'
              }}
            >
              <GlossaryAsideMenu
                terms={terms}
                selectedItemId={glossaryItemData.id}
                linkBase="/glossary/"
              />
            </div>

            <div className="column is-8">
              <a
                class="is-orange is-orange-border-bottom-left"
                href="/glossary"
              >
                Go back
              </a>

              <GlossaryContentSection
                title={glossaryItemData.title}
                description={glossaryItemData.description}
                popUpItems={definedTermsPopUpItems}
              />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

const GlossaryContentSection = ({ title, description, popUpItems }) => {
  return (
    <section className="search-results-section-content content">
      <h1 className="is-orange size-50 margin-top-40">
        {title}
      </h1>

      <ContentHTMLWithHoverPopUps
        className="margin-top-30"
        contentHTML={description.childMarkdownRemark.html}
        popUpItems={popUpItems}
      />
    </section>
  );
}

export const query = graphql`
query ($contentful_id: String!) {
    contentfulDefinedTerm(contentful_id: {eq: $contentful_id}) {
        id
        title
        slug
        shortDescription {
          shortDescription
        }
        description {
            childMarkdownRemark {
                html
            }
        }
    }
    allContentfulDefinedTerm(
        filter: {node_locale: {eq: "en-US"}}
        sort: {order: ASC, fields: title}
      ) {
        nodes {
          id
          slug
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
}
`